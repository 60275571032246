import React from "react";
import ProfileImage from "../../../assets/images/profileimg.png";
import homePageImgLeft from "../../../assets/images/Chat Graphics.png";
import homePageImgRight from "../../../assets/images/chatImgRight.png";
import Cookies from "js-cookie";

const WelcomePage = () => {
  const Username = Cookies.get("username");
  const ProfilePic = Cookies.get("profile_image");

  return (
    <div className="d-flex flex-column py-5 min-vh-100 text-center">
      <div className="container-fluid p-4">
        {/* Profile Section */}
        <div className="d-flex align-items-center mb-4">
          <img
            src={ProfilePic || ProfileImage}
            alt="Profile"
            className="rounded-circle me-3 border border-light shadow"
            width="60"
            height="60"
          />
          <div className="text-start">
            <h2 className="fw-medium">Welcome, {Username} !</h2>
          </div>
        </div>

        {/* Intro Text */}
        <p className="lead mt-3">
          Start connecting with music lovers at <b>ipartydjmix</b>. Chat now and
          plan your next Gig seamlessly!
        </p>

        {/* Cards Section */}
        <div className="row justify-content-center align-items-stretch mt-5 gap-5">
          {/* Left Box */}
          <div
            className="col-lg-5 col-md-5 d-flex flex-column rounded shadow-lg p-3"
            style={{ backgroundColor: "#e1e6eb", minHeight: "320px" }}
          >
            <img
              src={homePageImgLeft}
              alt="Chat with Clients"
              className="rounded mb-3"
              style={{ height: "150px", objectFit: "cover", width: "100%" }}
            />
            <h5 className="fw-bold mb-2">Chat with Clients</h5>
            <ul className="text-start ps-3" style={{ listStyleType: "disc" }}>
              <li>Instantly connect with potential clients.</li>
              <li>Discuss event details & special requests.</li>
              <li>Secure bookings with seamless communication.</li>
            </ul>
          </div>

          {/* Right Box */}
          <div
            className="col-lg-5 col-md-5 d-flex flex-column rounded shadow-lg p-3"
            style={{ backgroundColor: "#e1e6eb", minHeight: "320px" }}
          >
            <img
              src={homePageImgRight}
              alt="Manage Your Bookings"
              className="rounded mb-3"
              style={{ height: "150px", objectFit: "cover", width: "100%" }}
            />
            <h5 className="fw-bold mb-2">Manage Your Bookings</h5>
            <ul className="text-start ps-3" style={{ listStyleType: "disc" }}>
              <li>Keep track of your upcoming gigs.</li>
              <li>Get real-time updates on bookings.</li>
              <li>Plan your schedule efficiently.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomePage;
