import React, { useContext, useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import bootstrapPlugin from "@fullcalendar/bootstrap5";
import interactionPlugin from "@fullcalendar/interaction";
import rrulePlugin from "@fullcalendar/rrule";
import DatePicker from "react-datepicker";
import dashboard from "../../../Jsonfiles/Dashboard.json";

import {
  Popover,
  PopoverHeader,
  PopoverBody,
  ModalHeader,
  Modal,
  ModalBody,
  Button,
  Label,
  FormGroup,
  Input,
  Form,
  ModalFooter,
  Spinner,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { Col, Row, RSelect } from "../../../../src/components/Component";
import {
  convertTo24HourFormat,
  formatDate,
  formatDate1,
  getActiveEnvironmentVariable,
  setDateForPicker,
} from "../../../utils/Utils";
import { eventOptions, returnDate } from "./CalenderData";
import { useNavigate } from "react-router";
import ReactSelect from "react-select";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import {
  addSlotavailability,
  addUnavailability,
} from "../../../http/post/postApi";
import { getAvailableSlot, getEventsList } from "../../../http/get/getApi";
import Swal from "sweetalert2";
import { deleteAvailableTimeSlot } from "../../../http/delete/deleteApi";
import { useTheme } from "../../../layout/provider/Theme";
import { PlanContext } from "../../context/PlanContext";

const EventView = (event) => {
  const [mouseEnter, setMouseEnter] = useState(false);
  const { title, extendedProps, publicId } = event.event.event._def;
  return (
    <React.Fragment>
      <div
        id={publicId}
        onMouseEnter={() => setMouseEnter(true)}
        onMouseLeave={() => setMouseEnter(false)}
      >
        {title}
      </div>{" "}
      {/* <Popover placement="bottom" isOpen={mouseEnter} target={publicId}>
        <PopoverHeader>{title}</PopoverHeader>
        <PopoverBody>{extendedProps.description}</PopoverBody>
      </Popover> */}
    </React.Fragment>
  );
};

const CalenderApp = ({
  events1,
  events,
  onDelete,
  onEdit,
  setModal1,
  styles,
  selectedavailableSlot,
  newSlot,
}) => {
  const {
    geteventdata,
    getTransformSlotdata,
    setGetEventData,
    setTransformSlotdata,
  } = useContext(PlanContext);
  const [modalState, updateModal] = useState(false);
  // const [mockEvents, updateEvents] = useState(events1);
  const [mockEvents, updateEvents] = useState(events || []);
  const [startDate, setStartDate] = useState("");
  // const [id, setId] = useState('');
  // const [reason, setReason] = useState('');
  const [event, updateEvent] = useState({});
  const [theme, settheme] = useState();
  const [edit, updateEditModal] = useState(false);
  const [eventid, setEventId] = useState();
  const [dates, setDates] = useState({
    startDate: new Date(),
    startTime: "",
    endTime: "",
    endDate: new Date(),
  });
  // const [ wholeday, setWholeday] = useState(false);
  // const handleCheckboxChange = (event) => {
  //   setWholeday(event.target.checked); // Update state based on checkbox state
  // };

  // const [slotTransormData, setSlotTransformData] = useState(selectedavailableSlot);
  const [slotTransormData, setSlotTransformData] = useState(
    getTransformSlotdata || []
  );
  const naviaget = useNavigate();
  const queryClient = useQueryClient();

  console.log(events, "events");

  useEffect(() => {
    updateEvents(events);
    setSlotTransformData(newSlot);
  }, [events, newSlot]);

  const { skin } = useTheme();

  const darkModeStyles = {
    control: (provided, _state) => ({
      ...provided,
      backgroundColor: "transparent",
      color: "white",
      border: "1px solid #384d69",
    }),
    placeholder: (provided, _state) => ({
      ...provided,
      color: "#fff",
      opacity: 0.7,
    }),
    singleValue: (provided, _state) => ({ ...provided, color: "white" }),
    valueContainer: (provided, _state) => ({ ...provided, color: "white" }),
    option: (provided, _state) => ({
      ...provided,
      backgroundColor: "#141c26",
      color: "white",
      border: "1px solid #384d69",
    }),
  };

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleFormSubmit = (formData) => {
    let newEvent = {};
    newEvent = {
      id: event.id,
      className: theme.value,
      type: theme,
      title: formData.title,
      start: event.start,
      end: event.end,
      description: formData.description,
    };
    onEdit(newEvent);
    settheme("");
    toggleEdit();
  };
  const activeVariable = getActiveEnvironmentVariable();
  // get Available slot
  const {
    data: availableSlots,
    isLoading: availableSlotLoading,
    isError: availableSlotError,
    refetch: refetchAvailableSlots,
  } = useQuery({
    queryKey: ["get-available-slot"],
    queryFn: () => getAvailableSlot(),
    enabled: false,
  });

  useEffect(() => {
    refetchAvailableSlots(); // Manually refetch available slots
    refetchEvents(); // Manually refetch events
  }, []);

  const {
    data: newformateventData,
    isLoading,
    isError,
    refetch: refetchEvents,
  } = useQuery({
    queryKey: ["get-events"],
    queryFn: () => getEventsList({ id: "", page: "", limit: "", search: "" }),
    enabled: false,
    // staleTime: 5000,
    // cacheTime: 300000,
    // retry: 1,
  });

  // const {
  //   mutate: availableSlot,
  //   isLoading: istimeslotadding,
  //   error: istimesloterror,
  // } = useMutation({
  //   mutationKey: ["add-availabilty-timeslot"],
  //   mutationFn: (value) => addSlotavailability(value),
  //   onSuccess: (data) => {
  //     if (data.status) {
  //       queryClient.invalidateQueries("get-available-slot");
  //       refetchAvailableSlots();
  //       refetchEvents();
  //       // toggleRefetch()
  //     }
  //   },
  //   onError: (error) => {
  //     toast.error("Failed to update company profile.");
  //   },
  // });
  // const {
  //   mutate: UnavailableSlot,
  //   isLoading: isTimeSlotAdding,
  //   error: isTimeSlotError,
  // } = useMutation({
  //   mutationKey: ["add-availability-timeslot"],
  //   mutationFn: addUnavailability,
  //   onSuccess: (data) => {
  //     if (data.status) {
  //       queryClient.invalidateQueries("get-available-slot");
  //       refetchAvailableSlots();
  //       refetchEvents();
  //       // Uncomment if toggleRefetch is necessary
  //       // toggleRefetch();
  //     } else {
  //       toast.error(data.message || "Failed to update DJ unavailable slot.");
  //     }
  //   },
  //   onError: (error) => {
  //     toast.error(error?.info?.message || "An error occurred while updating time off.");
  //   },
  // });
  const {
    mutate: UnavailableSlot,
    isLoading: isTimeSlotAdding,
    error: isTimeSlotError,
  } = useMutation({
    mutationKey: ["add-availability-timeslot"],
    mutationFn: addUnavailability,
    onSuccess: (data) => {
      if (data.status) {
        toast.success("Updated off status.");
        queryClient.invalidateQueries("get-available-slot");
        refetchAvailableSlots();
        refetchEvents();
      } else {
        toast.error(data.message || "Failed to update .");
      }
    },
    onError: (error) => {
      toast.error(
        error?.info?.message || "An error occurred while updating time off."
      );
    },
  });

  const toggle = () => {
    updateModal(!modalState);
  };

  const deletetoggleModal = () => setDeleteModal(!deleteModal);

  const toggleEdit = () => {
    updateEditModal(!edit);
  };

  const handleEventClick = (info) => {
    console.log(info, "info from event");
    const event = mockEvents?.find(
      (item) => item?.id === info?.event?._def?.publicId
    );
    const fullId = info?.event?._def?.publicId;
    const eventIdS = fullId?.replace(/\D/g, ""); // This will give "101"
    const eventId = Number(eventIdS);

    // setFormData((prevData) => ({
    //   ...prevData, // Spread the previous form data
    //   id: eventId, // Set the id as eventId
    // }));
    setFormState((prevData) => ({
      ...prevData, // Spread the previous form data
      id: eventId, // Set the id as eventId
    }));
    setEventId(eventId);
    // const event1 = slotTransormData?.find((item) => item.id === eventid);
    const event1 = slotTransormData?.find((item) => item?.id === eventId);
    console.log(event1, "event1balu");
    setFormData((prevState) => ({
      ...prevState,
      startdate: new Date(event1?.start_date),
      enddate: new Date(event1?.end_date),
      From: new Date(`1970-01-01T${event1?.start_time}`),
      to: new Date(`1970-01-01T${event1?.end_time}`),
      interval: event1?.interval,
      occur: event1?.recurring,
      // days:["Mon", "Tue", "Wed"]
      days: event1?.days ? event1?.days : [],
    }));
    console.log(formData, "formData");
    // setFormState((prevState) => ({
    //   ...prevState,
    //   start_date: new Date(event1?.start_date),
    //   reason: event1?.reason,
    //   startTime: event1?.start_time,
    //   endTime: event1?.end_time,
    // }));
    setFormState((prevState) => ({
      ...prevState,
      start_date: new Date(event1?.start_date),
      reason: event1?.reason,
      startTime: prevState.startTime ? null : event1?.start_time,
      endTime: prevState.endTime ? null : event1?.end_time,
    }));

    updateEvent(event);
    settheme(event?.type);
    if (event?.id?.includes("default-event-id")) {
      toggle();
      // } else if (event1?.id) {
    } else {
      deletetoggleModal();
    }
    // deletetoggleModal();
    // setModal()
  };

  // const {
  //   mutate: deleteavlSlot,
  //   isLoading: isavailbaletimeslotDeleting,
  //   error: deleteError,
  // } = useMutation({
  //   mutationKey: ["delete-avlaibleSlot-by-id"],
  //   mutationFn: (data) => deleteAvailableTimeSlot(data),
  //   onSuccess: (data) => {
  //     if (data.status) {
  //       queryClient.invalidateQueries("get-available-slot");
  //       refetchAvailableSlots();
  //       refetchEvents();
  //     }
  //   },
  // });

  const {
    mutate: deleteavlSlot,
    isLoading: isavailbaletimeslotDeleting,
    error: deleteError,
  } = useMutation({
    mutationKey: ["delete-avlaibleSlot-by-id"],
    mutationFn: (data) => deleteAvailableTimeSlot(data),
    onSuccess: (data) => {
      if (data.status) {
        queryClient.invalidateQueries("get-available-slot");
        refetchAvailableSlots();
        refetchEvents();

        // Show the Swal.fire alert
        Swal.fire({
          title: "Deleted!",
          text: "Your record has been deleted.",
          icon: "success",
          timer: 2000, // Auto close after 2 seconds
          showConfirmButton: false,
        });
      }
    },
  });

  function getEventIdNumeric(eventId) {
    const parts = eventId?.split("-");
    return parts.pop();
  }

  // const handleDelete = (eventid) => {
  //   deletetoggleModal();
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: "You won't be able to revert this!",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes, delete it!",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       deleteavlSlot(eventid);
  //       Swal.fire({
  //         title: "Deleted!",
  //         text: "Your Slot has been deleted.",
  //         icon: "success",
  //         timer: 2000, // Auto close after 2 seconds
  //         showConfirmButton: false,
  //       });
  //     }
  //   });
  // };

  // const handleDelete = (eventId, formData) => {
  //   deletetoggleModal();
  //   Swal.fire({
  //     title: "Are you sure?",
  //     html: `

  //       <div style="text-align: left;">
  //         <label>
  //           <input type="radio" name="deleteType" value="one" style="margin-right: 8px;">
  //           <strong>Delete the record </strong><br>
  //           <small>This will delete the selected time off for the day.</small>
  //         </label>

  //       </div>
  //     `,
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes, delete",
  //     cancelButtonText: "Cancel",
  //     preConfirm: () => {
  //       const selectedOption = document.querySelector('input[name="deleteType"]:checked');
  //       if (!selectedOption) {
  //         Swal.showValidationMessage("Please choose an option!");
  //         return false;
  //       }
  //       return selectedOption.value;
  //     },
  //   })
  //   .then((result) => {
  //     if (result.isConfirmed) {
  //       // const deleteType = result.value; // 'one' or 'all'
  //       const date = new Date(formData.startdate);
  //       // const formattedDate = date.toISOString().split("T")[0];
  //       // Pass both eventId and deleteType as an object
  //       deleteavlSlot({

  //         id: eventId, // pass eventId
  //         // type: deleteType, // pass deletion type ('one' or 'all')
  //         // selectedDate: deleteType == "after" ? formattedDate : "",
  //       });

  //       Swal.fire({
  //         title: "Deleted!",
  //         // text: deleteType === "one" ? "Your record has been deleted." : "All records have been deleted.",
  //         icon: "success",
  //         timer: 2000, // Auto close after 2 seconds
  //         showConfirmButton: false,
  //       });
  //       setFormState({
  //         reason:""
  //       })
  //     }
  //   });
  // };

  const handleDelete = (eventId, formData) => {
    deletetoggleModal();

    // Assuming you are using Swal to confirm the action elsewhere or relying on an existing confirmation
    // Here we just call the deleteavlSlot directly

    deleteavlSlot({
      id: eventId, // Pass eventId for deletion
      // Add any other properties you need, e.g. selectedDate or deletion type
    });

    // Reset the form state after deletion
    setFormState({
      reason: "", // Reset reason field after deletion
    });
  };

  // const handleDelete = (eventId, formData) => {
  //   // Show loading state while deletion is processing
  //   if (isavailbaletimeslotDeleting) {
  //     // Optionally, you can display a loading indicator or prevent action if deleting is already in progress
  //     Swal.fire({
  //       title: "Please wait...",
  //       text: "Deleting the record.",
  //       icon: "info",
  //       showConfirmButton: false,
  //     });
  //     return; // Stop further execution if already deleting
  //   }

  //   deletetoggleModal();

  //   // Call deleteavlSlot directly
  //   deleteavlSlot({
  //     id: eventId, // Pass eventId for deletion
  //     // Add any other properties you need, e.g. selectedDate or deletion type
  //   });

  //   // Show success confirmation
  //   Swal.fire({
  //     title: "Deleted!",
  //     text: "Your record has been deleted.",
  //     icon: "success",
  //     timer: 2000, // Auto close after 2 seconds
  //     showConfirmButton: false,
  //   });

  //   // Reset the form state after deletion
  //   setFormState({
  //     reason: "", // Reset reason field after deletion
  //   });
  // };

  useEffect(() => {
    reset(event);
  }, [event]);

  function editEvent() {
    naviaget(`add-event?type=edit&id=${10}&from=fromEdit`);
    setModal1(false);
  }
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  const [formState, setFormState] = useState({
    id: "",
    start_date: new Date(),
    reason: "",
    startTime: "",
    endTime: "",
  });
  const formattedDate = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  }).format(selectedDate);
  // console.log("formattedDate",formattedDate);

  const toggleModal = () => {
    setModal(!modal);
  };

  const [formData, setFormData] = useState({
    id: null,
    startdate: new Date(),
    From: new Date(),
    enddate: new Date(),
    to: new Date(),
    occur: null,
    interval: null,
    byweekday: "",
    bymonthday: "",
    bymonth: "",
    days: [],
  });

  const handleDateClick = (info) => {
    console.log(info, "info from date");
    const selectedDate = new Date(info.dateStr);
    const today = new Date(); // Get today's date

    // Clear the time from both dates to only compare the day
    selectedDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);
    const transformedAvailableSlots12 = availableSlots?.data?.map((data) => ({
      id: data.id,
      start_date: data.start_date,
      start_time: data.start_time,
      end_time: data.end_time,
    }));
    console.log(selectedDate);

    if (selectedDate) {
      for (const slot of transformedAvailableSlots12 || []) {
        console.log(slot.start_date);

        if (slot.start_date == formatDate1(selectedDate)) {
          Swal.fire({
            icon: "warning",
            title: "Time OFF Already!",
            text: "You Time off that day already.",
            timer: 3000, // 2-second timer
            timerProgressBar: true, // Show progress bar
            showConfirmButton: false, // Hide the "OK" button
          });

          return; // Exit the function immediately
        }
      }
    }

    // Check if the selected date is in the past
    if (selectedDate < today) {
      // Show SweetAlert2 warning with a 2-second timer if the date is in the past
      Swal.fire({
        icon: "warning",
        title: "Invalid Date",
        text: "You cannot add a slot on a past date.",
        timer: 3000, // 2-second timer
        timerProgressBar: true, // Show progress bar
        showConfirmButton: false, // Hide the "OK" button
      });
      return; // Prevent further execution
    }
    setFormState({
      ...formState,
      id: null,
    });
    setFormData({
      ...formData,
      id: null,
      occur: null,
      interval: null,
      days: [],
    });
    const selectedDate1 = new Date(info.dateStr);

    setSelectedDate(selectedDate1); // This will now be a Date object

    // Set 'startdate' and 'enddate' in formData as Date objects
    // setFormData((prevState) => ({
    //   ...prevState,
    //   startdate: selectedDate1, // Set startdate as a Date object
    //   enddate: selectedDate1, // Set enddate as a Date object (or update as necessary)
    //   From: new Date(),
    //   to: new Date(),
    // }));
    setFormData((prevState) => {
      const currentTime = new Date();
      const tenMinutesLater = new Date(currentTime.getTime() + 10 * 60000); // Add 10 minutes

      return {
        ...prevState,
        startdate: selectedDate1, // Set startdate as a Date object
        start_date: selectedDate1,
        enddate: selectedDate1, // Set enddate as a Date object (or update as necessary)
        From: currentTime, // Set From as the current time
        to: tenMinutesLater, // Set to as 10 minutes later
        days: [],
      };
    });
    setFormState((prevState) => {
      const currentTime = new Date();
      const tenMinutesLater = new Date(currentTime.getTime() + 10 * 60000); // Add 10 minutes

      return {
        ...prevState,
        startdate: selectedDate1, // Set startdate as a Date object
        start_date: selectedDate1,
        enddate: selectedDate1, // Set enddate as a Date object (or update as necessary)
        From: currentTime, // Set From as the current time
        to: tenMinutesLater, // Set to as 10 minutes later
        days: [],
      };
    });

    const clickedDay = selectedDate1.toLocaleDateString("en-US", {
      weekday: "short",
    });

    const day = clickedDay.toLowerCase().slice(0, 2);

    // Set weekday, monthday, and month
    setFormData((prevState) => ({
      ...prevState,
      byweekday: day,
      bymonthday: selectedDate1.getDate(),
      bymonth: selectedDate1.getMonth() + 1,
      days: [...(prevState.days || []), clickedDay],
    }));

    toggleModal();
  };

  // const handleInputChange1 = (e) => {
  //   const { name, value } = e.target;
  //   setFormState((prevState) => ({
  //     ...prevState,
  //     [name]: value,
  //   }));
  // };
  const [error, setError] = useState("");
  const handleInputChange1 = (e) => {
    const { name, value } = e.target;

    // Allow only alphabets and spaces
    if (/^[a-zA-Z\s]*$/.test(value)) {
      setFormState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      setError(""); // Clear the error message when input is valid
    } else {
      setError("Only alphabets are allowed."); // Display error message for invalid input
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name == "startdate") {
      const selectedDate1 = new Date(value);
      const clickedDay = selectedDate1.toLocaleDateString("en-US", {
        weekday: "short",
      });
      const day = clickedDay.toLowerCase().slice(0, 2);
      setFormData((prevState) => ({
        ...prevState,
        byweekday: day,
      }));
      const monthday = selectedDate1.getDate();
      setFormData((prevState) => ({
        ...prevState,
        bymonthday: monthday,
      }));
      const month = selectedDate1.getMonth() + 1;
      setFormData((prevState) => ({
        ...prevState,
        bymonth: month,
      }));
    }
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleOccurChange = (selectedOption) => {
    setFormData((prevFormDate) => ({
      ...prevFormDate,
      occur: selectedOption.value,
    }));
  };
  const handleInervalChange = (selectedOption) => {
    setFormData((prevFormDate) => ({
      ...prevFormDate,
      interval: selectedOption.value,
    }));
  };

  const options = [
    { value: "DAILY", label: "DAILY" },
    { value: "WEEKLY", label: "WEEKLY" },
    { value: "MONTHLY", label: "MONTHLY" },
    { value: "YEARLY", label: "YEARLY" },
  ];

  const interval = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
  ];

  useEffect(() => {
    const transformedAvailableSlots = Array.isArray(availableSlots?.data)
      ? availableSlots?.data?.map((slot) => ({
          id: slot.id,
          start_date: slot.start_date,
          start_time: slot.start_time,
          end_date: slot.end_date,
          end_time: slot.end_time,
          recurring: slot.recurring,
          interval: slot.interval,
          days: slot.days,
          reason: slot.reason,
        }))
      : [];
    if (transformedAvailableSlots.length > 0) {
      console.log(transformedAvailableSlots, "transformedAvailableSlots");
      setSlotTransformData(transformedAvailableSlots);
    }
  }, [availableSlots, refetchAvailableSlots]);

  const formatTimeTo12Hour = (dateString) => {
    const date = new Date(dateString);

    // Get hours and minutes
    let hours = date.getHours();
    const minutes = date.getMinutes();

    // Determine AM or PM suffix
    const ampm = hours >= 12 ? "PM" : "AM";

    // Convert to 12-hour format
    hours = hours % 12 || 12; // The hour '0' should be '12'

    // Add leading zero to minutes if needed
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

    // Return formatted time
    return `${hours}:${formattedMinutes} ${ampm}`;
  };

  useEffect(() => {
    const eventsData = Array.isArray(newformateventData?.data)
      ? newformateventData.data
          .map((event) => {
            if (
              !event ||
              !event.start_date_time ||
              !event.end_date_time ||
              !event.event_name ||
              !event.event_type_name
            ) {
              return null;
            }

            const startDate = new Date(event?.start_date_time)
              .toISOString()
              .split("T")[0];
            const endDate = new Date(event?.end_date_time)
              .toISOString()
              .split("T")[0];
            const startTime = formatTimeTo12Hour(event?.start_date_time);
            const endTime = formatTimeTo12Hour(event?.end_date_time);

            return {
              id: "default-event-id-" + event.id,
              title: event.event_name,
              start: startDate,
              end: endDate,
              starttime: startTime,
              endtime: endTime,
              description: event.event_type_name,
            };
          })
          .filter((event) => event !== null)
      : [];

    if (eventsData.length > 0) {
      updateEvents(eventsData);
    }
  }, [newformateventData]);

  // const handleFormSubmit1 = async(e) => {
  //   e.preventDefault();

  //   if (!formData.occur || !formData.interval) {
  //     Swal.fire({
  //       title: "Info!",
  //       text: "Please select both Recurring and Interval options.",
  //       icon: "info",
  //     });
  //     return; // Prevent form submission
  //   }

  //   // Format the date to YYYY-MM-DD
  //   const formatDate = (date) => {
  //     return new Date(date).toISOString().slice(0, 10); // YYYY-MM-DD format
  //   };

  //   // Format the time to HH:MM (24-hour format)
  //   const formatTime = (date) => {
  //     return new Date(date).toTimeString().slice(0, 5); // HH:MM format
  //   };

  //   // Assuming formData contains event_start_date, event_start_time, event_end_date, and event_end_time
  //   const formattedFormData = {
  //     ...formData,
  //     startdate: formatDate(formData.startdate),
  //     From: formatTime(formData.From),
  //     enddate: formatDate(formData.enddate),
  //     to: formatTime(formData.to),
  //   };

  //   const updatedSlotData = {
  //     id: formData.id, // Include the id for updating the specific slot
  //     ...formattedFormData,
  //   };

  //   await availableSlot(updatedSlotData); // Pass formatted data
  //   // availableSlot(formattedFormData); // Pass formatted data

  //   setModal(false);
  //   // deletetoggleModal();
  //   if (updatedSlotData.id) {
  //     setFormData({
  //       ...formData,
  //       id: null, // Reset the id to null after update/add
  //     });
  //     Swal.fire({
  //       title: "Updated!",
  //       text: "Your Slot has been updated.",
  //       icon: "success",
  //       timer: 2000, // Auto close after 2 seconds
  //       showConfirmButton: false,
  //     });
  //     deletetoggleModal();
  //   } else {
  //     setFormData({
  //       ...formData,
  //       id: null, // Reset the id to null after update/add
  //     });
  //     Swal.fire({
  //       title: "Added!",
  //       text: "Your Slot has been Added.",
  //       icon: "success",
  //       timer: 2000, // Auto close after 2 seconds
  //       showConfirmButton: false,
  //     });
  //   }

  //   setFormData({
  //     ...formData,
  //     id: null, // Reset the id to null after update/add
  //   });

  // };

  // const handleFormSubmit1 = async (e) => {
  //   e.preventDefault();

  //   if (!formData.occur || !formData.interval || formData.days.length === 0) {
  //     Swal.fire({
  //       title: "Info!",
  //       text: "Please select both Recurring, Interval and repeat On options.",
  //       icon: "info",
  //     });
  //     return; // Prevent form submission
  //   }

  //   let updateType = null;

  //   // If there's an id, it means we're updating; otherwise, it's adding a new slot
  //   if (formData.id) {
  //     // Show Swal to select "one" or "all" update option only for updating slots
  //     const { value } = await Swal.fire({
  //       title: "Update Slot",
  //       html: `

  //       <br/><br/>
  //         <div style="text-align: left;">
  //           <label>
  //             <input type="radio" name="updateType" value="one" style="margin-right: 8px;">
  //             <strong>Update this slot only</strong><br>
  //             <small>This will only update the selected time slot.</small>
  //           </label>
  //           <br><br>
  //           <label>
  //             <input type="radio" name="updateType" value="all" style="margin-right: 8px;">
  //             <strong>Update all recurring slots</strong><br>
  //             <small>This will update all recurring time slots related to this event.</small>
  //           </label>
  //         </div>
  //       `,
  //       icon: "question",
  //       showCancelButton: true,
  //       confirmButtonText: "Update",
  //       cancelButtonText: "Cancel",
  //       preConfirm: () => {
  //         const selectedOption = document.querySelector('input[name="updateType"]:checked');
  //         if (!selectedOption) {
  //           Swal.showValidationMessage("Please choose an option!");
  //           return false;
  //         }
  //         deletetoggleModal();
  //         return selectedOption.value;
  //       },
  //     });

  //     // Exit if no update type was selected
  //     if (!value) return;

  //     // Set updateType to the chosen value ('one' or 'all')
  //     updateType = value;
  //   }

  //   // Format the date to YYYY-MM-DD
  //   const formatDate = (date) => {
  //     return new Date(date).toISOString().slice(0, 10); // YYYY-MM-DD format
  //   };

  //   // Format the time to HH:MM (24-hour format)
  //   const formatTime = (date) => {
  //     return new Date(date).toTimeString().slice(0, 5); // HH:MM format
  //   };

  //   // Format form data (dates and times)
  //   const formattedFormData = {
  //     ...formData,
  //     startdate: formatDate(formData.startdate),
  //     From: formatTime(formData.From),
  //     enddate: formatDate(formData.enddate),
  //     to: formatTime(formData.to),
  //   };

  //   // Include type only if it's an update, otherwise omit it
  //   const updatedSlotData = {
  //     id: formData.id, // Include the id only if updating
  //     ...formattedFormData,
  //     ...(updateType && { type: updateType }), // Include type if it's an update
  //   };

  //   const day1 = formData.startdate.toLocaleDateString("en-US", { weekday: 'short' });

  //   if (formData.days.length > 0 && formData.days.includes(day1)) {
  //     await availableSlot(updatedSlotData); // Pass the formatted data
  //   }else{
  //     Swal.fire({
  //       title: "Info!",
  //       text: "Please select correct days",
  //       icon: "info",
  //     });
  //     return; // Prevent form submission

  //   }

  //   // Reset modal and form after submission
  //   setModal(false);

  //   if (formData.id) {
  //     // If updating, show a specific success message
  //     Swal.fire({
  //       title: "Updated!",
  //       text: updateType === "one" ? "Your slot has been updated." : "All slots have been updated.",
  //       icon: "success",
  //       timer: 2000, // Auto close after 2 seconds
  //       showConfirmButton: false,
  //     });
  //   } else {
  //     // If adding a new slot, show a different success message
  //     Swal.fire({
  //       title: "Added!",
  //       text: "Your Slot has been added.",
  //       icon: "success",
  //       timer: 2000, // Auto close after 2 seconds
  //       showConfirmButton: false,
  //     });
  //   }

  //   // Reset form data
  //   setFormData({
  //     ...formData,
  //     id: null, // Reset the id to null after update/add
  //   });
  // };

  // const handleFormSubmit12 = async (values , e) => {
  //   e.preventDefault();
  //   UnavailableSlot(values);
  // };

  // const handleFormSubmit12 = (e) => {
  //   e.preventDefault();

  //   const { id, start_date, reason } = formState;

  //   // if (!id || !start_date || !reason) {
  //   //   toast.error("Please fill out all required fields: ID, Start Date, and Reason.");
  //   //   return;
  //   // }

  //   UnavailableSlot({ id, start_date, reason });
  //   setModal(false);
  // };

  const handleFormSubmit12 = (e) => {
    e.preventDefault();

    // Trigger the mutation
    UnavailableSlot({
      startDate: formatDate1(formState?.start_date),
      id: formState?.id,
      reason: formState?.reason,
      startTime: formState?.startTime
        ? convertTo24HourFormat(formState?.startTime)
        : "",
      endTime: formState?.endTime
        ? convertTo24HourFormat(formState?.endTime)
        : "",
      //        startTime : wholeday
      //   ? "00:15"
      //   : formState?.startTime
      //   ? convertTo24HourFormat(formState?.startTime)
      //   : "",

      //  endTime : wholeday
      //   ? "23:45"
      //   : formState?.endTime
      //   ? convertTo24HourFormat(formState?.endTime)
      //   : "",
    });

    setModal(false);
    setDeleteModal(false);
    setFormState({
      reason: "",
      endTime: "",
      startTime: "",
    });
  };

  const data = [
    {
      id: "default-event-id-" + 1,
      title: "Annual Event",
      rrule: {
        freq: "yearly", // yearly recurrence
        interval: 1, // every year
        bymonth: [8], // August (Month 8)
        bymonthday: [15], // 15th day of August
        byweekday: null, // null because it’s not restricted to specific weekdays
        dtstart: "2024-08-15", // start date
        until: null, // end date (optional, if you want to specify an end date)
      },
      allDay: true, // Event spans the whole day
      color: "green", // different color for the annual event
    },
    {
      id: "default-event-id-" + 2,
      title: "Annual Event",
      rrule: {
        freq: "yearly", // yearly recurrence
        interval: 1, // every year
        bymonth: [9], // August (Month 8)
        bymonthday: [15], // 15th day of August
        byweekday: null, // null because it’s not restricted to specific weekdays
        dtstart: "2024-08-15", // start date
        until: null, // end date (optional, if you want to specify an end date)
      },
      allDay: true, // Event spans the whole day
      color: "green", // different color for the annual event
    },
  ];

  function convertTo12HourFormat(time24) {
    const [hour, minute] = time24.split(":");
    let hourNum = parseInt(hour, 10);
    const ampm = hourNum >= 12 ? "PM" : "AM";
    hourNum = hourNum % 12 || 12;
    return `${hourNum}:${minute.padStart(2, "0")} ${ampm}`;
  }

  const rruleFormatData = availableSlots?.data?.map((data) => {
    // const startTime12 = convertTo12HourFormat(data.start_time);
    // const endTime12 = convertTo12HourFormat(data.end_time);
    const reason = data.reason;
    const sday = new Date(data.start_date).getDate();
    // const eday = new Date(data.end_date).getDate();
    // const recurring = data.recurring === null || undefined ? "daily" : data.recurring?.toLowerCase();
    const recurring = "";
    return {
      id: "default-slot-id-" + data.id,
      // title: `${startTime12} to ${endTime12}`,
      // title: `${reason}`,
      // title: `${reason ? `${dashboard?.[activeVariable]?.section1?.map((item) => item?.title)} Not Availabel `: `${reason ? `${dashboard?.[activeVariable]?.section1?.map((item) => item?.title)} Not Availabel `}`,
      // title: reason
      // ? `${dashboard?.[activeVariable]?.section1?.map((item) => item?.title).join(", ")} Not Available`
      // : `${dashboard?.[activeVariable]?.section1?.map((item) => item?.title).join(", ")} Not Available`,
      title: reason ? "Time Off" : "Time Off",

      // rrule: {
      //   freq: recurring,
      //   interval: data.interval ? JSON.parse(data.interval) : 1, // default interval if missing
      //   byweekday: recurring === "daily" || recurring === "monthly" || recurring === "yearly" ? null : [data.byweekday],
      //   bymonthday:
      //     recurring === "yearly" || (recurring === "monthly" && sday <= eday)
      //       ? Array.from({ length: eday - sday + 1 }, (_, i) => i + sday) // Create array from sday to eday
      //       : data.bymonthday && recurring !== "weekly" && recurring !== "daily"
      //       ? [parseInt(data.bymonthday, 10)]
      //       : null,
      //   bymonth: recurring === "yearly" ? [parseInt(data.bymonth, 10)] : null,
      //   dtstart: data.start_date,
      //   until: recurring === "yearly" ? null : data.end_date || null,
      // },
      color: "black",
      type: "slot",
      start: `${data?.start_date}`,
      end: `${data?.start_date}`,
      backgroundColor: "black",
    };
  });

  // console.log("rruleFormatData", rruleFormatData);

  const totalData = [...mockEvents, ...(rruleFormatData || [])];
  // const totalData = [...mockEvents, ...(data || [])];

  const daysOfWeek = [
    { label: "Sun", value: "Sun" },
    { label: "Mon", value: "Mon" },
    { label: "Tue", value: "Tue" },
    { label: "Wed", value: "Wed" },
    { label: "Thu", value: "Thu" },
    { label: "Fri", value: "Fri" },
    { label: "Sat", value: "Sat" },
  ];

  const handleDaySelection = (selectedDay) => {
    setFormData((prevFormData) => {
      // Check if the day is already selected
      const isSelected = prevFormData.days.includes(selectedDay);

      // If selected, remove it; if not, add it
      const updatedDays = isSelected
        ? prevFormData.days.filter((day) => day !== selectedDay) // Remove day
        : [...prevFormData.days, selectedDay]; // Add day
      return {
        ...prevFormData,
        days: updatedDays, // Update the days array
      };
    });
  };

  return (
    <React.Fragment>
      <FullCalendar
        plugins={[
          dayGridPlugin,
          timeGridPlugin,
          listPlugin,
          bootstrapPlugin,
          interactionPlugin,
          rrulePlugin,
        ]}
        // events={mockEvents}
        events={totalData}
        // events={data}
        // eventClick={(info) => handleEventClick(info)}
        eventClick={handleEventClick}
        dateClick={handleDateClick}
        // initialView="dayGridMonth"
        // initialView="customMonth"
        initialView="dayGridMonth"
        dayMaxEventRows={2}
        headerToolbar={{
          left: "title prev,next",
          center: null,
          right: "today dayGridMonth,timeGridWeek,timeGridDay,listWeek",
        }}
        themeSystem="bootstrap5"
        height={800}
        contentHeight={780}
        eventContent={(e) => <EventView event={e} />}
        aspectRatio={3}
        editable={true}
        droppable={true}
        views={{
          customMonth: {
            type: "dayGridMonth",
            fixedWeekCount: false,
          },
        }}
        // validRange={{
        //   start: new Date().toISOString().split("T")[0], // Disables previous dates
        // }}
      />

      <Modal isOpen={modalState} toggle={toggle} className="modal-md">
        <ModalHeader className={event && event.className} toggle={toggle}>
          {event && event?.title}
        </ModalHeader>
        <ModalBody>
          <Row className="gy-3 py-1">
            <Col sm="6">
              <h6 className="overline-title">Start Time</h6>
              <p id="preview-event-start">{`${
                event && returnDate(event?.start)
              }  (${event && event?.starttime})`}</p>
            </Col>
            <Col sm="6" id="preview-event-end-check">
              <h6 className="overline-title">End Time</h6>
              <p id="preview-event-end">{`${event && returnDate(event?.end)} (${
                event && event?.endtime
              })`}</p>
            </Col>
            <Col sm="10" id="preview-event-description-check">
              <h6 className="overline-title">Description</h6>
              <p id="preview-event-description">
                {event && event?.description}
              </p>
            </Col>
          </Row>
          <ul className="d-flex justify-content-between gx-4 mt-3">
            <li>
              {/* <Button
                color="primary"
                // onClick={() => {
                //   toggle();
                //   toggleEdit();
                // }}
                onClick={editEvent}
              >
                Edit Event
              </Button> */}
            </li>
            <li>
              {/* <Button
                color="danger"
                className="btn-dim"
                onClick={() => {
                  toggle();
                  onDelete(event && event.id);
                }}
              >
                Delete
              </Button> */}
            </li>
          </ul>
        </ModalBody>
      </Modal>

      <Modal isOpen={modal} toggle={toggleModal} className="modal-md">
        <ModalHeader>SELECTED DATE & REASON</ModalHeader>
        <ModalBody>
          <form
            className="form-validate is-alter"
            onSubmit={handleFormSubmit12}
          >
            <Row className="gx-4 gy-3">
              <Col sm="12">
                <div className="form-group">
                  {/* <label className="form-label">Start Date &amp; Time</label> */}
                  {/* <label className="form-label">Start Date</label> */}
                  {console.log("first", formState?.start_date)}
                  <Row className="gx-2">
                    <div
                      className="w-100"
                      style={{ background: " rgb(222, 221, 221)" }}
                    >
                      <div style={{ padding: "3px" }}>{formattedDate}</div>

                      {/* <DatePicker 
                        selected={formState?.start_date}
                        onChange={(date) => handleInputChange({ target: { name: "startdate", value: date } })}
                        className="form-control date-picker"
                        dateFormat="dd/MM/yyyy"
                        minDate={new Date()}
                        
                      /> */}
                    </div>
                    {/* <div className="w-45">
                      <DatePicker
                        selected={formData.From}
                        onChange={(time) => handleInputChange({ target: { name: "From", value: time } })}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        className="form-control date-picker"
                      />
                    </div> */}
                  </Row>
                </div>
              </Col>
              <Col sm="12">
                <div className="form-group">
                  {/* <div className=""> */}
                  <label className="form-label">Select Time-Off Period</label>
                  {/* <label className="ps-5 pe-2"> Select Whole Day</label> */}
                  {/* <input type="checkbox" className="ps-2"  onChange={handleCheckboxChange}></input></div> */}
                  <Row className="gx-2">
                    <div className="w-45">
                      <div className="form-control-wrap has-timepicker">
                        <DatePicker
                          // selected={dates.startTime}
                          placeholderText="Enter Start Time"
                          selected={formState.startTime}
                          // onChange={(date) => setDates({ ...dates, startTime: date })}
                          // onChange={(date) => setFormState((prevState) => ({

                          //   ...prevState,
                          //   startTime: date
                          // }))}
                          onChange={(date) => {
                            console.log("onchange date", date); // Log the selected date
                            setFormState((prevState) => ({
                              ...prevState,
                              startTime: date,
                            }));
                          }}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          className="form-control date-picker"
                          // disabled={wholeday === true}
                        />
                      </div>
                    </div>
                    <div className="w-45">
                      <div className="form-control-wrap has-timepicker">
                        <DatePicker
                          // selected={dates.endTime}
                          selected={formState.endTime}
                          placeholderText="Enter end Time"
                          onChange={(date) => {
                            console.log("onchange date", date); // Log the selected date
                            setFormState((prevState) => ({
                              ...prevState,
                              endTime: date,
                            }));
                          }}
                          minTime={
                            formState.startTime
                              ? new Date(
                                  new Date(formState.startTime).getTime() +
                                    15 * 60 * 1000
                                )
                              : new Date().setHours(0, 0, 0, 0)
                          }
                          maxTime={new Date().setHours(23, 59, 59, 999)}
                          // onChange={(date) => setDates({ ...dates, endTime: date })}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          className="form-control date-picker"
                          // disabled={wholeday === true}
                        />
                      </div>
                    </div>
                  </Row>
                </div>
              </Col>
              <Col sm="12">
                <div className="form-group">
                  <label className="form-label">
                    Please enter the reason for requested time off
                  </label>
                  <Row className="gx-2">
                    <div className="">
                      {/* <input
            type="text"
            id="reason"
            name="reason"
            placeholder="Reason for time off"
            value={formState.reason}
            onChange={handleInputChange1}
            className="w-100 form-control"
          /> */}
                      <input
                        type="text"
                        id="reason"
                        name="reason"
                        placeholder="Reason for time off"
                        value={formState.reason}
                        onChange={handleInputChange1}
                        className={`w-100 form-control ${
                          error ? "is-invalid" : ""
                        }`}
                      />
                      {error && <div className="invalid-feedback">{error}</div>}
                    </div>
                  </Row>
                </div>
              </Col>
              {/* <Col sm="6">
                <div className="form-group">
                  <label className="form-label">End Date &amp; Time</label>
                  <Row className="gx-2">
                    <div className="w-55">
                      <DatePicker
                        selected={formData?.enddate}
                        onChange={(date) => handleInputChange({ target: { name: "enddate", value: date } })}
                        className="form-control date-picker"
                        dateFormat="dd/MM/yyyy"
                        minDate={formData?.startdate ? new Date(formData.startdate) : null} // Set minDate to start date
                      />
                    </div>
                    <div className="w-45">
                      <DatePicker
                        selected={formData.to}
                        onChange={(time) => handleInputChange({ target: { name: "to", value: time } })}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        className="form-control date-picker"
                        // If start date and end date are the same, prevent selecting the same time or earlier
                        minTime={
                          formData?.startdate &&
                          formData?.enddate &&
                          formData?.startdate.getTime() === formData?.enddate?.getTime()
                            ? new Date(new Date(formData?.From).getTime() + 15 * 60000) // 15 minutes after start time
                            : new Date().setHours(0, 0) // Otherwise, allow any time
                        }
                        maxTime={new Date().setHours(23, 45)} // Optional: Limit end time to the end of the day
                      />
                    </div>
                  </Row>
                </div>
              </Col> */}

              {/* <FormGroup className="col-md-6">
                <Label for="description">Recurring</Label>
                <ReactSelect
                  options={options} 
                  value={formData?.occur?.label}
                  onChange={handleOccurChange}
                  styles={skin === "dark" ? { ...darkModeStyles, ...styles } : styles}
                />
              </FormGroup> */}
              {/* <FormGroup className="col-md-6">
                <Label for="interval">Interval</Label>
                <ReactSelect
                  options={interval}
                  value={formData.interval?.label}
                  onChange={handleInervalChange}
                  styles={skin === "dark" ? { ...darkModeStyles, ...styles } : styles}
                />
              </FormGroup> */}

              {/* <Col size="12">
                <div className="form-group">
                  <label className="form-label">Repeat On</label>
                  <ul
                    className="d-flex gx-2 mt-1"
                    style={{
                      display: "flex",
                      alignItems: "start",
                      flexWrap: "nowrap", 
                      overflowX: "auto", 
                      padding: 0, 
                      listStyle: "none", 
                    }}
                  >
                    {daysOfWeek.map((day, idx) => (
                      <li
                        key={idx}
                        className="form-group"
                        style={{
                          marginRight: "10px",
                          display: "flex",
                          alignItems: "center",
                          height: "40px", 
                        }}
                      >
                        <label
                          className="form-label justify-content-center"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginRight: "7px",
                            lineHeight: "20px", 
                            height: "100%",
                          }}
                        >
                          <input
                            type="checkbox"
                            value={day.value}
                            checked={formData.days.includes(day.value)} 
                            onChange={() => handleDaySelection(day.value)} 
                            style={{
                              marginRight: "5px",
                              appearance: "none",
                              borderRadius: "50%",
                              border: "2px solid #ccc",
                              width: "20px",
                              height: "20px",
                              background: formData.days.includes(day.value) ? process.env.REACT_APP_THEME_COLOR : "white",
                              verticalAlign: "middle", 
                            }}
                          />
                          <span style={{ verticalAlign: "middle" }}>{day.label}</span> 
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>
              </Col> */}

              <Col size="12">
                <ul className="d-flex justify-content-end gx-4 mt-1">
                  <li>
                    <Button type="submit" color="primary">
                      {isTimeSlotAdding ? (
                        <Spinner size="sm" color="light" />
                      ) : (
                        <div> Time OFF</div>
                      )}
                    </Button>
                  </li>
                  <li>
                    <Button color="danger" onClick={toggleModal}>
                      Cancel
                    </Button>
                  </li>
                </ul>
              </Col>
            </Row>
          </form>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={deleteModal}
        toggle={deletetoggleModal}
        className="modal-md"
      >
        <ModalHeader toggle={deletetoggleModal}>
          Edit Time Off Unavailability
        </ModalHeader>
        <ModalBody>
          <form
            className="form-validate is-alter"
            onSubmit={handleFormSubmit12}
          >
            <Row className="gx-4 gy-3">
              {/* Start Date & Time */}
              <Col sm="12">
                <div className="form-group">
                  {/* <label className="form-label">Start Date &amp; Time</label> */}
                  <label className="form-label">Start Date </label>

                  <Row className="gx-2">
                    <div className="w-100">
                      <DatePicker
                        selected={formState?.start_date}
                        onChange={(date) =>
                          handleInputChange({
                            target: { name: "startdate", value: date },
                          })
                        }
                        className="form-control date-picker"
                        dateFormat="dd/MM/yyyy"
                        minDate={new Date()}
                      />
                    </div>
                    <div className="w-45">
                      {/* <DatePicker
                        // selected={formData.From ? new Date(formData.From) : null}
                        selected={formData?.From || null}
                        // value={formData.From ? new Date(`${formData.From}`) : null}
                        onChange={(time) => handleInputChange({ target: { name: "From", value: time } })}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        className="form-control date-picker"
                      /> */}
                    </div>
                  </Row>
                </div>
              </Col>

              {/* End Date & Time */}
              <Col sm="6">
                <div className="form-group">
                  {/* <label className="form-label">End Date &amp; Time</label> */}
                  <Row className="gx-2">
                    <div className="w-100">
                      {/* <DatePicker
                        selected={formData?.enddate || null}
                        // value={formData.enddate ? new Date(formData.enddate) : null}
                        onChange={(date) => handleInputChange({ target: { name: "enddate", value: date } })}
                        className="form-control date-picker"
                        dateFormat="dd/MM/yyyy"
                        minDate={formData?.startdate ? new Date(formData.startdate) : null} // Set minDate to start date
                      /> */}
                    </div>
                    <div className="w-45">
                      {/* <DatePicker
                        selected={formData?.to || 0}
                        // value={formData.to ? new Date(`${formData.to}`) : null}
                        onChange={(time) => handleInputChange({ target: { name: "to", value: time } })}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        className="form-control date-picker"
                        minTime={
                          formData?.startdate &&
                          formData?.enddate &&
                          formData?.startdate.getTime() === formData?.enddate?.getTime()
                            ? new Date(new Date(formData?.From).getTime() + 15 * 60000) // 15 minutes after start time
                            : new Date().setHours(0, 0) // Otherwise, allow any time
                        }
                        maxTime={new Date().setHours(23, 45)} // Optional: Limit end time to the end of the day
                      /> */}
                    </div>
                  </Row>
                </div>
              </Col>
              <Col sm="12">
                <div className="form-group">
                  {console.log("formState.startTime", formState.startTime)}
                  <label className="form-label">Select Time-Off Period</label>
                  <Row className="gx-2">
                    <div className="w-50">
                      <div className="form-control-wrap has-timepicker">
                        <DatePicker
                          // selected={dates.startTime}
                          placeholderText="Enter Start Time"
                          // selected={formState.startTime}
                          // selected={formState.startTime ? new Date(formState.startTime) : ""}
                          // selected="Fri Jan 24 2025 15:31:14 GMT+0530"
                          selected={
                            formState.startTime
                              ? new Date(formState.startTime)
                              : ""
                          }
                          // onChange={(date) => setDates({ ...dates, startTime: date })}
                          // onChange={(date) => setFormState((prevState) => ({

                          //   ...prevState,
                          //   startTime: date
                          // }))}
                          onChange={(date) => {
                            console.log("onchange date", date); // Log the selected date
                            setFormState((prevState) => ({
                              ...prevState,
                              startTime: date,
                            }));
                          }}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          className="form-control date-picker"
                        />
                      </div>
                    </div>
                    <div className="w-50">
                      <div className="form-control-wrap has-timepicker">
                        <DatePicker
                          // selected={dates.endTime}
                          // selected={formState.endTime}
                          selected={
                            formState.endTime ? new Date(formState.endTime) : ""
                          }
                          placeholderText="Enter end Time"
                          onChange={(date) => {
                            console.log("onchange date", date); // Log the selected date
                            setFormState((prevState) => ({
                              ...prevState,
                              endTime: date,
                            }));
                          }}
                          minTime={
                            formState.startTime
                              ? new Date(
                                  new Date(formState.startTime).getTime() +
                                    15 * 60 * 1000
                                )
                              : new Date().setHours(0, 0, 0, 0)
                          }
                          maxTime={new Date().setHours(23, 59, 59, 999)}
                          // onChange={(date) => setDates({ ...dates, endTime: date })}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          className="form-control date-picker"
                        />
                      </div>
                    </div>
                  </Row>
                </div>
              </Col>
              {/* Recurring Option */}
              {/* <FormGroup className="col-md-6">
                <Label for="description">Recurring</Label>
                <ReactSelect
                  options={options} // Assuming you have defined options
                  value={options.find((option) => option?.value === formData?.occur || null)} // Map value correctly
                  onChange={handleOccurChange}
                  styles={skin === "dark" ? { ...darkModeStyles, ...styles } : styles}
                />
              </FormGroup> */}

              {/* Interval */}
              {/* <FormGroup className="col-md-6">
                <Label for="interval">Interval</Label>
                <ReactSelect
                  options={interval} // Assuming you have defined interval options
                  value={interval.find((option) => option.value === formData.interval || null)} // Map value correctly
                  onChange={handleInervalChange}
                  styles={skin === "dark" ? { ...darkModeStyles, ...styles } : styles}
                />
              </FormGroup> */}

              {/* <Col size="12">
                <div className="form-group">
                  <label className="form-label">Repeat On</label>
                  <ul
                    className="d-flex gx-2 mt-1"
                    style={{
                      display: "flex",
                      alignItems: "start",
                      flexWrap: "nowrap", // Ensure items stay on one line (change to 'wrap' if you want them to wrap on small screens)
                      overflowX: "auto", // Allow horizontal scrolling if needed
                      padding: 0, // Remove default padding of ul
                      listStyle: "none", // Remove default list styling
                    }}
                  > */}
              {/* {daysOfWeek.map((day, idx) => (
                      <li
                        key={idx}
                        className="form-group"
                        style={{ marginRight: "10px", display: "flex", alignItems: "center" }}
                      >
                        <label
                          className="form-label"
                          style={{ display: "flex", alignItems: "center", marginRight: "7px" }}
                        >
                          <input
                            type="checkbox"
                            value={day.value}
                            checked={formData.days.includes(day.value)} // Use includes to check if selected
                            onChange={() => handleDaySelection(day.value)} // Handle selection/deselection
                            style={{
                              marginRight: "5px",
                              appearance: "none",
                              borderRadius: "50%",
                              border: "2px solid #ccc",
                              width: "20px",
                              height: "20px",
                              background: formData.days.includes(day.value) ? process.env.REACT_APP_THEME_COLOR : "white",
                            }}
                          />
                          <span>{day.label}</span>
                        </label>
                      </li>
                    ))}
                  </ul> */}
              {/* </div> */}
              {/* </Col> */}

              {/* Buttons */}
              <Col sm="12">
                <div className="form-group">
                  <label className="form-label">Reason:</label>
                  <Row className="gx-2">
                    <div className="">
                      <input
                        type="text"
                        id="reason"
                        name="reason"
                        placeholder="Reason for time off"
                        value={formState.reason}
                        onChange={handleInputChange1}
                        className={`w-100 form-control ${
                          error ? "is-invalid" : ""
                        }`}
                      />
                      {error && <div className="invalid-feedback">{error}</div>}
                    </div>
                  </Row>
                </div>
              </Col>
              <Col size="12">
                <ul className="d-flex justify-content-end gx-2 mt-1">
                  <li>
                    <Button color="primary" type="submit">
                      {isTimeSlotAdding ? (
                        <Spinner size="sm" color="light" />
                      ) : (
                        <div> Update</div>
                      )}
                    </Button>
                  </li>
                  <li>
                    {/* <Button color="danger" onClick={() => handleDelete(eventid, formData)}>
                      Delete
                    </Button> */}
                    {console.log("eventid", eventid)}
                    <Button
                      color="danger"
                      onClick={() => handleDelete(eventid, formState)}
                    >
                      {isavailbaletimeslotDeleting ? (
                        <Spinner size="sm" color="light" />
                      ) : (
                        <div> Delete</div>
                      )}
                    </Button>
                  </li>
                  <li>
                    <Button color="secondary" onClick={deletetoggleModal}>
                      Cancel
                    </Button>
                  </li>
                </ul>
              </Col>
            </Row>
          </form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default CalenderApp;
